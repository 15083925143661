* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.mem{
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.who_onyx{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
}

.team {
  color: #fff;
  font-size: 2rem;
}
.Who_Heading{
  color: white;
  text-align: center;
  font-size: 3rem;
  font-family: Forum, sans-serif;
  padding: 2rem;
}
.who_description{
  color: white;
  font-size: 2rem;
  font-family: Forum, sans-serif;
  text-align: center;
}
.Achivements_box{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Achivements_heading{
  font-size: 3rem;
  font-family: Forum, sans-serif;
}
.Achivements{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Achivement_logo{
  font-size: 4rem;
  color: black;
}
.Achivement{
  width: 35%;
  color: black;
  background-color: #30DE81;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Forum, sans-serif;
  font-size: 1rem;
  margin: 1rem 0rem;
  padding: 1rem;
  text-align: center;
}
.verticle_line{
  display: inline-block;
  background-color: #30DE81;
  width: 1px;
  height: 100%;
}
.trophy{
  margin: 0rem 1rem;
}

.Team_Box{
  width: 100vh;
  /* height: 100vw; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-around;
  width: 100%;
}
.Person_card{
  margin:2rem;
  position: relative;
  width: 250px;
  height: 350px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

}
.Person_pic{
  width: 250px;
  height: 350px;
  object-fit: cover;
  border-radius: 10px;
}

.Person_details{
  z-index: 2;
  position: absolute;
  width: 100%;
  color: white;
  
}
.Person_name{
  font-family: Forum, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
}
.inner-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; /* Adjust the height of the shadow as needed */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
.Person_position{
  font-family: Forum, sans-serif;
  font-size: 1.2rem;
  text-align: center;
  margin: .6rem;
}

.mem_box {
  color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Mem_benifits{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2rem;
  width: 100%;
  height: 70%;

}
.mem_heading{
  font-size: 2rem;
  font-family: Forum, sans-serif;
}
.Bennifits{
  font-size: 1.5rem;
  font-family: Forum, sans-serif;
  font-weight:bold;
  padding: 1rem;
  margin :.5rem;
  text-align: center;
  width: 30%;
  background-color: #30DE81;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 20px;
}
.Benifit_logo{
  width: 100%;
  font-size: 6rem;
}

@media only screen and (max-width: 1020px) {
  .about{
    flex-direction: column;
    height: 150vh;
  }
  .Achivements_box{
    width: 100%;
    height: 40vh;
  }
  .Achivements_heading{
    font-size: 2.5rem;
  }
  .Who_Heading{
    font-size: 2.5rem;
    padding: .5rem;
    margin: 1rem;
  }
  .who_description{
    width: 100%;
    font-size: 1.5rem;
  }
  .Achivements_box{
    height: 110vh;
  }
  .Achivements{
    height: 70%;
  }
  .verticle_line{
    display: none;
  }
  .who_onyx{
    width: 100%;
    height: 30vh;
    padding: 1.5rem;
  }
  .mem_heading{
    font-size: 1.3rem;
    text-align: center;
    padding: .6rem;
  }
  .Bennifits{
    font-size: 1.5rem;
    margin :.5rem 0rem;
    width: 90%;
    height: 20%;
  }
  .brackets{
    font-size: 1.5rem;
    display: none;
  }
  .mem_box{
    height: 200vh;
  }
  .Mem_benifits{
    height: 80%;
  }
  .Benifit_logo{
    margin-bottom: .7rem;
  }
}