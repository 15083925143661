.cards {
  background: black;
  padding-bottom: 4rem;
}
.Check_heading{
  padding: 2rem;
  color: white;
}
.Entravaganza{
  width: fit-content;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.cards__item {
  cursor: pointer;
  border-radius: 1rem;
  position: relative;
  width: 350px;
  height: 350px;
  margin: 0 1rem;
  border-radius: 10px;
}
.cards__item__pic-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 350px;
  width: 350px;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}
.cards__item__img {
  display: block;
  width: 350px;
  height: 350px;
  transition: all 0.2s linear;
  object-fit: cover;
}
.cards__item__info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, .4);
  top: 0;
  height: 350px;
  width: 350px;
}

.cards__item:hover .cards__item__img {
  transform: scale(1.1); 
  
}
.cards__item__label{
  padding: 2rem;
  color: white;
  font-size: xx-large;
}
.cards__item__text {
  color: white;
  font-size: 18px;
  width: 95%;
  text-align: center;
  /* line-height: 24px; */
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__container{
    flex-direction: row;
    width: 100vw;
    overflow-x: auto;
  }
  .cards__item{
    width: 100%;
    height: 100%;
    
  }
}
@media only screen and (min-width: 628px) {
  .cards__item{
    overflow: hidden;
  }
}

