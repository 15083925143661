@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.Entravaganza_Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height:300vh;
    width: 100vw;
    font-family: Forum, sans-serif;
    overflow: hidden;
    
}

#particles canvas {
    z-index: -10;
    position: absolute;
    height: 100%;
    width: 100vw;
}

.Entravaganza_Heading {
    position: relative;
    /* width: 100%; */
    text-align: center;
    color: white;
    /* padding: 1rem 2rem; */
    margin: 1rem 0rem;
    font-size: 3rem;
    font-family: Forum, sans-serif;
    color: black;
    /* -webkit-text-stroke: 0.1vw #383d52; */
}
.Entravaganza_Heading::before {
    /* padding: 1rem 2rem; */
    /* padding-right: 1rem; */
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #30DE81;
    -webkit-text-stroke: 0.12vw #30DE81;
    overflow: hidden;
    border-right: 2px solid #30DE81;
    animation: animate 3s linear;
}
@keyframes animate {
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
}
.Entravaganza_Description{
    width: 60%;
    color: white;
    font-family: Forum, sans-serif;
    padding: 1rem;
    text-align: center;
    margin-bottom: 2rem;
    font-size: large;
}

.Entravaganza_Schedule_Box {
    color: white;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.Sponsors_box{
    color: white;
   margin: 2rem;
}
.Sponsers_heading{
    margin-bottom: 2rem;
    font-family: Forum, sans-serif;
    font-size: 2rem;
    color: #30DE81;
    text-align: center;
}
.Sponser_logo{
    height: 150px;
    background-color: white;
    padding: 1rem;
    margin: .5rem;
}
.Sponsers_logos{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Entravaganza_Schedule_heading {
    font-size: x-large;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Forum, sans-serif;
    font-size: 2rem;
    color: #30DE81;
    margin-top: 2rem;
    margin-bottom: 4rem;
}
.Sponsers_box{
    margin: 1rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Incubation_heading{
    font-family: Forum, sans-serif;
    text-align: center;
    font-size: 1.5rem;
}
.Event_circle {
    border-radius: 50%;
    background-color: #30DE81;
    display: inline-block;
    height: 13px;
    width: 13px;
    
}
.Event_line{
    width: 3px;
    height: 100%;
    display: inline-block;
    background-color: white;
}

.Event_flowline {
    width: 2%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Events_box {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    width: 100%;
}
.SubEvent_box{
    padding: .2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.Event_Date{
    text-align: center;
    padding-bottom: 1rem;
    font-size: x-large;
    width: 100%;
    font-family: Forum, sans-serif;
    margin-top: -.5rem;
}
.SubEvent_heading{
    font-size: larger;
    color: #30DE81;
    /* color: rgb(95, 181, 95); */
    margin: .4rem .2rem;
    font-family: Forum, sans-serif;
    text-align: center;
}
.SubEvent_venue{
    font-size: smaller;
    margin: .1rem;
    display: flex;
    align-items: center;
    font-family: Forum, sans-serif;
}
.SubEvent_Time{
    font-size: smaller;
    display: flex;
    align-items: center;
    margin: .1rem;
    font-family: Forum, sans-serif;
}
.SubEvent_venue_icon{
    font-size: large;
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.Empty_box{
    width: 30%;
}
.Event_box_right{
    width: 30%;
    padding-left: 0.5rem;
}
.Event_box_left{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 1rem;
}
.Entravaganza_gallery{
    color: white;
    display: flex;
    overflow-x: auto;
    width: 90vw;
}
.Entravaganza_pic{
    height: 200px;
    width: 200px;
    margin: .3rem;
    object-fit: cover;
}
.Entravaganza_gallery_Heading{
    color: white;
    font-family: Forum, sans-serif;
    font-size: xx-large;
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
}
.Entravaganza_gallery_container{
    margin-top: 7rem;
}

@media only screen and (max-width: 640px) {
    .Entravaganza_Schedule_Box{
        width: 98%;
    }
    .Entravaganza_Description{
        padding: .4rem;
        width: 100%;
    }
    .Empty_box{
        width: 45%;
    }
    .Event_box_right{
        width: 45%;
    }
    .Event_box_left{
        width: 45%;
    }
    .Entravaganza_Main {
        height: 300vh;
    }
    .Sponsers_logos{
        flex-direction: column;
    }
  }